<template>
  <div class="home container-fluid">
    <div class="row">
      <div class="col-12">
        <Title msg="lohres-log"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="hinweis">Under Construction...</p>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title.vue'

export default {
  name: 'Home',
  components: {
    Title
  },
  data: function () {
    return {
    }
  }
}
</script>
