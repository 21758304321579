<template>
  <div class="title">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h1 {
  color: transparent;
  font-size: 36px;
  letter-spacing: 2px;
  line-height: 36px;
  margin-bottom: 25px;
  text-transform: uppercase;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
}

.title {
  margin-top: 200px;
}

@media (min-width: 576px) {
  h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 100px;
    line-height: 100px;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 100px;
    line-height: 100px;
  }
}
</style>
