<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="nav">
            <router-link class="btn btn-primary" to="/"><i class="fa fa-home" aria-hidden="true"></i> Home</router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="nav">
            <router-link class="btn btn-primary" to="/imprint"><i class="fa fa-legal" aria-hidden="true"></i> Impressum</router-link> |
            <router-link class="btn btn-primary" to="/privacy-policy"><i class="fa fa-leanpub" aria-hidden="true"></i> Datenschutzerklärung</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#app {
  color: #344761;
  position: relative;
  top: 0;
  width: 100%;
}

.nav {
  padding: 30px 0;
}

.nav a {
  font-weight: bold;
}

.nav a.router-link-exact-active {
  color: #42b983;
}

@media (min-width: 576px) {
  #app {
    margin: 0 auto;
    width: 570px;
  }
}

@media (min-width: 768px) {
  #app {
    width: 760px;
  }
}

@media (min-width: 992px) {
  #app {
    width: 980px;
  }
}

@media (min-width: 1200px) {
  #app {
    width: 1160px;
  }
}
</style>
